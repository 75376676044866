//==================================================================================
// Shortcode Panel
//==================================================================================

section.panel {
  width: 100%;
  padding: 50px 0;
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  
  @include small() {
    background-image: unset!important;
  }
  
  &.panel-bg-grey {
    background-color: $lightgrey;
  }
  
  &.panel-bg-white {
    background-color: $white;
  }
  
  .panel-container .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include responsive($large) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .title-panel-recommendations {
    background-color: $black;
    color: $white;
    text-align: center;
    font-size: 1.4rem;
    line-height: 20px;
  }

  .panel-recommendations-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 23px;
    background-color: #F4F5FA;
    border: solid 1px #DBEDDE;
    border-top: none;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: $yellow;
    }
  }

  .panel-recommendations-item > img {
    height: 50px;
    width: auto;
    object-fit: contain;
    object-position: center;
    font-family: 'object-fit: contain; object-position: center;';
  }

  .panel-recommendations-item > span {
    margin-top: 10px;
    text-decoration: none;
    color: $black;
    font-weight: 700;
  }

  .panel-right {
    margin-top: 30px;
    padding: 0 15px!important;
    
    @include responsive($large) {
      padding: 0!important;
    }
    
    .panel-right__description a {
      @extend %button-inline;
      margin-top: 0;
      font-weight: 700;
    }
    .panel-right__description {
      width: 100%;
    }
    .panel-right__description a.button {
      margin-top: 30px;
    }
    
    @include responsive($large) {
      margin-top: 0;
    }
    
    h2 {
      text-transform: initial;
      font-size: 38px;
      color: $black;
      margin: 0 0 25px 0;
      line-height: 100%;
      font-weight: 500;
      span {
        font-weight: 800;
      }
    }

    ul {
      padding-inline-start: 0;
    }

    a {
      margin-top: 25px;
    }

    a > i {
      margin-right: 5px;
    }
  }
}