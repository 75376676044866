//==================================================================================
// Page Résultats de Recherche
//==================================================================================

.search {
	.search-results-header, .search-no-results {
		margin-top: 50px;
	}
	.tab-pane {
		padding: 10px;
	}
	.entry-title {
		margin: 10px 0 5px 0;
		a {
			color: $black;
			text-decoration: none;
			font-size: 24px;
			text-transform: uppercase;
			font-weight: bold;
		}
	}
	
	.updated {
		font-style: italic;
		color: #8d8d8d;
	}
	
	.post header {
		margin-bottom: 10px;
	}
	
	.entry-summary {
		padding-bottom: 10px;
		border-bottom: solid 1px lightgray;
	}
}

//==================================================================================
// Page CVthèque
//==================================================================================

.form-cvtheque {
	width: 99%;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	
	input {
		border-radius: 0;
	}
	
	& + p {
		text-align: center;
	}
	
	& + p > input[type="submit"] {
		margin: 20px auto;
		width: 99%;
		max-width: 300px;
		padding: 10px 15px;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 14px;
		cursor: pointer;
		background-color: $secondary-color;
		border-radius: 3px;
		border: none;
		oultine: 0;
	}
}

//==================================================================================
// Page Formation Professionnelle
//==================================================================================

body.formation-professionnelle {
	line-height: 200%;
	h1 {
		text-transform: uppercase;
		font-weight: bold;
		margin-top: 55px;
	}
	
	section.testimonials {
		margin-top: 0;
		padding-top: 0;
	}
}


//==================================================================================
// Page Maître d'apprentissage
//==================================================================================

.resume-maitre-apprenti {
	margin-top: 70px;
}

.maitre-apprenti-description {
	margin-top: 30px;
}