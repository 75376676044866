//==================================================================================
// Shortcode Testimonials
//==================================================================================

section.testimonials {
  width: 100%;
  position: relative;
  
  &.one-item {
    padding: 0;
  }
  
  &.three-items {
    padding: 60px 0;
    
    .owl-item {
      border-right: solid 1px #D0D4E5;
      border-collapse: collapse;
    }
  }
  
  .owl-item {
    display: flex;
    justify-content: center;
  }
  
  .owl-dots {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    top: -125px;
    z-index: 10;
    width: 100%;
    padding: 8px 15px;
  }

  h2 {
    color: $primary-color;
    margin: 0 0 50px 0;
    span {
      font-weight: 800;
    }
  }

  .testimonial-item {
    padding: 5px 30px 15px 30px;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    .testimonial-image {
      width: 89px;
      height: 89px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';
    }

    .testimonial-author, .testimonial-profession {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      width: 100%;
      text-align: center;
    }

    .testimonial-author {
      text-transform: uppercase;
      margin-top: 10px;
      font-weight: 700;
    }

    .testimonial-message {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
  }
  
  .slider-1-item {
    margin: 0;
    padding: 0 10px;
  }
  .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    position: unset;
    width: 100%;
    padding: 8px 15px 30px 15px;
    .owl-dot span {
      border: 2px solid #444;
      background-color: transparent;
    }
    .owl-dot.active span {
      background-color: #444;
    }
  }
}