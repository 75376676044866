//==================================================================================
// Shortcode Title Text
//==================================================================================

section.title-text {
	height: 100%;
	
	@include responsive($large) {
		padding: 95px 90px 100px 70px;
	}
	
	.tt-content {
		max-width: 500px;
	}
}