//==================================================================================
// Shortcode Number
//==================================================================================

section.number {
  padding: 90px 0 30px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
  @include responsive($large) {
    background-size: 350%;
  }
  
  .row {
    justify-content: center;
  }
  h4 {
    font-size: 35px!important;
    font-weight: 800;
    color: $white;
    margin-bottom: 0;
  }
  p {
    text-transform: uppercase;
    white-space: pre-wrap;
    color: $white;
  }
  .text-center:first-child p, .text-center:nth-child(2) p, .text-center:nth-child(3) p, .text-center:nth-child(4) p {
    margin-bottom: 4rem;
  }
}