//==================================================================================
// Grid settings
//==================================================================================

$main-sm-columns	: 12;
$sidebar-sm-columns	: 4;

//==================================================================================
// Couleurs
//==================================================================================

$black 					: #444444;
$yellow 				: #FFEE00;
$white 					: #ffffff;
$lightgrey 				: #e5e7f1;

$primary-color 			: $black;
$secondary-color 		: $yellow;
$tertiary-color 		: $white;

$primary-color-hover 	: darken($primary-color, 5%);
$secondary-color-hover 	: darken($secondary-color, 5%);
$tertiary-color-hover 	: darken($tertiary-color, 5%);

$border-color			: #dbdeed;


//==================================================================================
// Responsive Mixins
//==================================================================================

// Breakpoints

$small	: 450px;
$medium	: 768px;
$large	: 1200px;

@mixin responsive ($width) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin responsive-max ($width) {
	@media screen and (max-width: $width) {
		@content;
	}
}

@mixin small() {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@keyframes glitch {
	0%,
	1%,
	2%,
	3%,
	4%,
	51%,
	52%,
	53%,
	54%,
	55%,
	73%,
	74%,
	75%,
	76% {
		text-shadow: 1px 0 0 var(--c01), -1px 0 0 var(--c02);
	}
	10%,
	5%,
	56%,
	57%,
	58%,
	59%,
	6%,
	60%,
	67%,
	68%,
	69%,
	7%,
	70%,
	77%,
	78%,
	79%,
	8%,
	80%,
	9% {
		text-shadow: -1px 0 0 var(--c01), 1px 0 0 var(--c02);
	}
	11%,
	12%,
	13%,
	14%,
	15%,
	43%,
	44%,
	45%,
	46%,
	81%,
	82%,
	83%,
	84%,
	85% {
		text-shadow: 0.5px 0 0 var(--c01), -0.5px 0 0 var(--c03);
	}
	16%,
	17%,
	18%,
	19%,
	20%,
	47%,
	48%,
	49%,
	50%,
	86%,
	87%,
	88%,
	89%,
	90% {
		text-shadow: -1px 0 0 var(--c01), 1px 0 0 var(--c02);
	}
	21%,
	22%,
	23%,
	24%,
	25%,
	26%,
	27%,
	28%,
	29%,
	30%,
	92%,
	93%,
	94%,
	95% {
		text-shadow: .7px 0 0 var(--c02), -.7px 0 0 var(--c03);
	}
	100%,
	31%,
	32%,
	33%,
	34%,
	35%,
	36%,
	37%,
	38%,
	39%,
	40%,
	96%,
	97%,
	98%,
	99% {
		text-shadow: -1px 0 0 var(--c02), 1px 0 0 var(--c03);
	}
	41% {
		text-shadow: 50px 0 0 var(--c02), -50px 0 0 var(--c03);
	}
	42% {
		text-shadow: 0 0 0 var(--c02), 0 0 0 var(--c03);
	}
	61% {
		text-shadow: 30px 0 0 var(--c01), -30px 0 0 var(--c03);
	}
	62% {
		text-shadow: 0 0 0 var(--c01), 0 0 0 var(--c03);
	}
	63%,
	64%,
	65%,
	66% {
		text-shadow: .5px 0 0 var(--c01), -.5px 0 0 var(--c02);
	}
	71% {
		text-shadow: 70px 0 0 var(--c01), -70px 0 0 var(--c02);
	}
	72% {
		text-shadow: 0 0 0 var(--c01), 0 0 0 var(--c02);
	}
	91% {
		text-shadow: 60px 0 0 var(--c03), -60px 0 0 var(--c02);
	}
}