//==================================================================================
// Footer
//==================================================================================

footer.content-info {
	color: $black;
	background-color: $white;
	.col-lg-9 {
		padding: 30px 0 0 0;
	}
	img {
		width: auto !important;
	}
	.col-lg-8 {
		padding-left: 50px;
		
		@include responsive($large) {
			padding-left: 15px;
		}
	}
	.left {
		padding-left: 50px;
	}
	.left img {
		margin: 15px 0 30px 0;
	}
	.logo {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0 0 20px 0;
		border-bottom: 1px solid #87888A;
	}
	.logo img {
		margin-right: 64px;
	}
	h3 {
		font-size: 16px !important;
	    font-weight: 700;
	    text-transform: uppercase;
	    margin-top: 15px;
	    margin-bottom: 25px;
	}
	a, a:hover {
		font-size: 14px;
		line-height: 24px;
		color: inherit;
		text-decoration: none;
	}
	.bottom {
		position: absolute;
		bottom: 0;
		padding: 14px 15px 12px 15px;
		border-top: 1px solid #DBDEED;
		font-size: 12px;
		background-color: #F4F5FA;
	}
	.bottom .a42 {
		position: absolute;
		right: 15px;
		top: 14px;
	}
	.bottom p {
		margin-bottom: 0;
		padding-right: 20px;
	}
	.scrolltop {
		display: none;
		position: fixed;
		bottom: 30px;
		right: 30px;
	}
	.scrolltop.active {
		display: block;
	}
	a.social {
		display: inline-block;
		margin-right: 19px;
	}
	.form-control {
		height: 30px;
		padding-left: 2px;
	}
	.form-group {
		margin-top: 30px;
	}
	.form-group.newsletter-name {
		display: flex;
	    align-items: baseline;
	    justify-content: space-between;
	    input:first-of-type {
    		margin-right: 5px;
		}
		input:last-of-type {
    		margin-left: 5px;
		}
	}
	.mc4wp-response {
	    font-size: 12px;
	    margin-top: 15px;
	}
	.text-3 a, .text-4 a, .text-5 a, .text-8 a { @extend %button-inline; }
	.text-6 h3 { margin-bottom: 5px !important; }
	.text-8 { transform: translateY(-15px); }
}

@media(max-width: 800px) {
	footer.content-info .bottom { position: relative; }
}


//==================================================================================
// Retour en haut
//==================================================================================

.return-top-cta {
	position: fixed;
	bottom: 25px;
	right: 0;
	padding: 15px 28px;
	background-color: $black;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px 0 0 4px;
	z-index: 9042;
}