::selection {
  background-color: #ffee00;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

.row::before,
.row::after {
  display: none;
}

.vc_row::before,
.vc_row::after {
  display: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: $primary-color;
  overflow-y: visible;
  overflow-x: hidden;
}

body.clipped {
  overflow-y: hidden;
}

a, .btn {
  text-decoration: none!important;
}

.hide-desktop {
  display: block;
  
  @include responsive($large) {
    display: none!important;
  }
}

.hide-mobile {
  display: none!important;
  
  @include responsive($large) {
    display: flex!important;
  }
}

//==================================================================================
// Niveaux de titres
//==================================================================================

h1, h2, h3 {
  line-height: 120%;
  margin-bottom: 25px;
  
  span, strong {
    font-weight: 800;
    text-transform: uppercase;
  }
}

h1 {
  font-size: 28px!important;
}

h2 {
  font-size: 24px!important;
}

h3 {
  font-size: 20px!important;
}

h4 {
  font-size: 18px!important;
}

@include responsive($large) {
  h1 {
    font-size: 38px!important;
  }
  
  h2 {
    font-size: 35px!important;
  }
  
  h3 {
    font-size: 24px!important;
  }
  
  h4 {
    font-size: 20px!important;
  }
}

//==================================================================================
// Liste à puces
//==================================================================================

ul {
  list-style-type: disc!important;
  //list-style-image: url('/wp-content/uploads/2019/07/arrow-right.svg')!important;
  list-style-position: inside!important;
}

//==================================================================================
// Images
//==================================================================================

figure {
  padding: 0;
  margin: 0;
}

//==================================================================================
// OWL Carousel
//==================================================================================

//.owl-stage.owl-stage.owl-stage {
//  transform: unset!important;
//}