//==================================================================================
// Boutons & Liens
//==================================================================================

%reset-button {
	 text-decoration: none!important;
	 display: inline-block;
	 cursor: pointer;
	 border: none;
	
	 &:hover {
		 color: $black;
	 }
 }

%common-button {
	@extend %reset-button;
	font-weight: bold;
	text-transform: uppercase;
	padding: 12px 30px;
	border-radius: 4px;
	transition: all 0.3s;
}

%button-inline {
	@extend %reset-button;
	padding: 2px 0;
	position: relative;
	color: $black;
	
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 1px;
		background-color: $secondary-color;
		bottom: 0;
		left: 0;
		transition: all 0.1s ease-in-out;
	}
	
	&:hover::before {
		height: 3px;
	}
}

%button-white {
	@extend %common-button;
	background-color: $white!important;
	color: $black!important;
	
	&:hover {
		color: $white!important;
		background-color: $black!important;
	}
}

%button-black {
	@extend %common-button;
	background-color: $black!important;
	color: $white!important;
	
	&:hover {
		color: $black!important;
		background-color: $white!important;
	}
}

%button-yellow {
	@extend %common-button;
	background-color: $secondary-color!important;
	color: $primary-color!important;
	
	&:hover {
		color: $primary-color!important;
		background-color: $secondary-color-hover!important;
	}
}

%button-grey {
	@extend %common-button;
	background-color: $primary-color!important;
	color: $tertiary-color!important;
	
	&:hover {
		color: $tertiary-color!important;
		background-color: $primary-color-hover!important;
	}
}

.btn-left {
	position: relative;
}

.btn-center {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.btn-right {
	position: relative;
	left: 100%;
	transform: translateX(-100%);
}

a {
	@extend %reset-button;
}

.button, button {
	@extend %common-button;
}

.inline-button {
	@extend %button-inline;
}

.button {
	@extend %common-button;
}

.btn-white, .btn-white a, .btn-white button {
	@extend %button-white;
}

.btn-black, .btn-black a, .btn-black button {
	@extend %button-black;
}

.btn-yellow, .btn-yellow a, .btn-yellow button {
	@extend %button-yellow;
}

.btn-grey, .btn-grey a, .btn-grey button {
	@extend %button-grey;
}

a.contact, a.doc {
	display: inline-block;
	line-height: 14px;
	
	&::before {
		margin-right: 10px;
	}
}

a.contact {
	&::before {
		content: url( /wp-content/uploads/2019/05/PICTO-contact.svg);
		display: inline-block;
		width: 13px;
		height: auto;
	}
	
	&:hover::before {
		content: url( /wp-content/uploads/2019/05/PICTO-contact-blanc.svg);
	}
}

a.doc {
	&::before {
		content: url( /wp-content/uploads/2019/05/PICTO-documentation.svg);
		display: inline-block;
		width: 13px;
		height: auto;
	}
	
	&:hover::before {
		content: url( /wp-content/uploads/2019/05/PICTO-documentation-blanc.svg);
	}
}

.wrap.container p:not(#breadcrumbs) a {
	@extend %button-inline;
}

button#cn-accept-cookie {
    background: #ffee00;
    color: #444;
    text-shadow: none;
    border: 0;
    padding: 5px 20px;
    border-radius: 5px;
}

button#cn-accept-cookie:hover { background: white; }

header a, footer a {
	border-bottom: none!important;
	font-size: inherit !important;
	&:before { display: none; }
	 &:hover {
		 border-bottom: none!important;
		 font-size: inherit !important;
		 &:before { display: none; }
	 }
}

footer {
	.widget.text-3,
	.widget.text-4,
	.widget.text-5 {
		a { position: relative; }
		a::before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 0;
			background-color: $yellow;
			bottom: 0;
			left: 0;
			transition: all 0.1s ease-in-out;
		}
		a:hover::before {
			height: 2px;
		}
	}
}

// Lien réclamation

//section.reclamation {
//	width: 100%;
//	padding: 50px 0 20px 0;
//	display: flex;
//	align-items: center;
//	justify-content: center;
//}

//.vc_btn3-container.button.btn-yellow.vc_btn3-inline {
//	background-color: transparent!important;
//
//	a, button {
//		background-color: $secondary-color!important;
//		color: $primary-color!important;
//		border: none!important;
//
//		&:hover {
//			color: $primary-color!important;
//			background-color: $secondary-color-hover!important;
//		}
//	}
//}