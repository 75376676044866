//==================================================================================
// Shortcode Tous les Partenaires
//==================================================================================

section.all-partners {
	
	.all-partners-category {
		padding: 10px;
		
		@include responsive($large) {
			padding: 40px calc(((100vh - 10px) / 12) * 2);
		}
		
		&.all-partners-bg-grey {
			background-color: $lightgrey;
		}
		
		&.all-partners-bg-white {
			background-color: $white;
		}
	}
	
	.all-partners-category-title {
		text-align: center;
		margin-bottom: 40px;
	}
	
	.owl-all-partners {
		.owl-item {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		
		figure.item {
			height: 150px;
			width: 150px;
		}
		
		figure > img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
	}

	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		right: -15px;
		top: -110px;
		z-index: 10;
		width: 100%;
		padding: 8px 15px;
		.owl-dot span {
			border: 2px solid #444;
			background-color: transparent;
		}
		.owl-dot.active span {
			background-color: #444;
		}
	}
}