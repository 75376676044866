//==================================================================================
// Single Formation
//==================================================================================

.single-formation-container {
	transform: translateY(-95px);
	
	@include responsive($large) {
		transform: translateY(0);
	}
	
	.formation-intro {
		margin-top: 25px;
		padding: 20px;
		background-color: #e5e7f1;
		
		p {
			text-align: center;
			font-size: 1rem;
			margin: 0;
		}
	}
	
	.vc_empty_space {
		height: 40px!important;
		
		@include responsive($large) {
			height: 120px!important;
		}
	}
	
	.fixed-title-formation {
		font-size: 20px;
		font-weight: normal;
		
		@include responsive($large) {
			font-weight: bold;
		}
		
		b {
			font-weight: normal;
			
			@include responsive($large) {
				font-weight: bold;
			}
		}
	}
	
	.formation-title {
		margin-bottom: 30px;
		text-transform: uppercase;
		font-weight: normal;
		
		span.formation-long-title {
			font-weight: bold;
			font-size: 24px;
		}
		
		span.formation-short-title {
			font-weight: normal;
			font-size: 1.5rem;
		}
	}
	
	.formation-description {
		margin-bottom: 30px;
	}
	
	.formation-image img {
		object-fit: cover;
		object-position: center;
		height: 400px;
		margin-bottom: 20px;
		
		@include responsive($large) {
			width: 100%;
			height: 100%;
			min-height: 500px;
			max-height: 800px;
			margin-bottom: 0;
		}
	}
	
	.list-formations {
		li {
			margin: 4px 0;
		}
	}
}