//==================================================================================
// Shortcode Slider
//==================================================================================

section.slider {
	position: relative;

	@include responsive($large) {
		&:after {
			content: url(/wp-content/uploads/2019/07/mini-logo.svg);
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 80%);
			z-index: 2;
		}
	}

	.slider-wrapper {
		height: 100vh;
	}
	
	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 0!important;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: rgba(0,0,0, 0.5);
		padding: 10px 50px;
		.owl-dot span {
			border: 2px solid white;
			background-color: transparent;
		}
		.owl-dot.active span {
			background-color: white;
		}
	}
	
	.item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		
		@include responsive($large) {
			background-size: cover;
		}
		
		.content {
			margin-bottom: 150px;
		}
	}
	h2 {
		color: $white;
		white-space: pre-wrap;
		
		@include responsive($large) {
			font-size: 80px!important;
			font-weight: 800;
			text-transform: uppercase;
			line-height: 75px;
			margin-bottom: 0;
		}

		span {
			display: block;
			font-size: 45px;
			font-weight: 400;
			text-transform: initial;
			line-height: 40px;
			margin-top: 20px;
		}
	}
	.button.contact, button.doc {
		margin: 15px 30px 15px 0;
	}
	
	
	/**
	 * Formulaire JE TROUVE MA FORMATION
	 */
	
	.search-formation-container {
		padding: 25px 0;
		
		@include responsive($large) {
			position: absolute;
			bottom: 15%;
			left: 50%;
			transform: translateX(-50%);
			z-index: 20;
			padding: 0 15px;
		}
		
		h3 {
			display: inline-block;
			padding: 10px 20px;
			width: 100%;
			margin: 0;
			background-color: $black;
			color: $white;
			text-transform: uppercase;
			font-weight: bold;
			border-radius: 0;
			font-size: 16px;
			text-align: center;
			
			@include responsive($large) {
				width: auto;
				border-radius: 4px 4px 0 0;
				text-align: left;
			}
		}
		
		.find-formation-form {
			background-color: $white;
			border-radius: 0;
			padding: 40px 30px;
			
			@include responsive($large) {
				border-radius: 0 4px 4px 4px;
			}
			
			.row {
				flex-direction: column;
				flex-wrap: nowrap;
				align-items: center;
				justify-content: flex-start;
				
				@include responsive($large) {
					flex-direction: row;
					justify-content: space-between;
				}
			}
			
			.col {
				flex-basis: initial;
				margin: 10px 0;
				
				@include responsive($large) {
					flex-basis: 0;
					margin: 0;
				}
			}
			
			label {
				width: 20px;
				margin: 0 10px 0 0;
				display: flex;
				align-items: center;
			}
			
			.sf-input {
				position: relative;
				
				//&::before {
				//	content: '';
				//	-webkit-clip-path: polygon(50% 50%, 0 0, 100% 0);
				//	clip-path: polygon(50% 50%, 0 0, 100% 0);
				//	height: 10px;
				//	width: 10px;
				//	position: absolute;
				//	right: 20px;
				//	top: 60%;
				//	transform: translateY(-50%);
				//	font-size: 1.4rem;
				//	background-color: $black;
				//	pointer-events: none;
				//}
			}
			
			select {
				width: calc(100% - 30px);
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border: none;
				border-bottom: 1px solid $black;
				border-radius: 0;
				padding: 2px;
				cursor: pointer;
			}
			
			button[type="submit"] {
				@extend %button-yellow;
				border-bottom: none;
				display: inline-block;
				margin: 15px 0 0 0;
				
				@include responsive($large) {
					margin: 0 15px 0 0;
				}
			}
		}
	}
}

section.simpleslider {

	.item {
		
		&:before {
			content: '';
		    position: absolute;
		    top: 0;
		    left: 0;
		    z-index: 0;
		    width: 100%;
		    height: 100%;
		    background-color: rgba(0,0,0,0.6);
		}

		.content {
			margin-bottom: 0;
    		position: relative;
    		z-index: 2;

			.button {
				margin-top: 30px;
			}
		}
	}
}