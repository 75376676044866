//==================================================================================
// Archive Articles
//==================================================================================

body.blog,
body.single .related-post {
	.item { margin: 20px 0; }
	
	.item article {
		height: 100%;
		border-radius: 4px;
		box-shadow: 0 0 40px -20px rgba($black, 0.4);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 20px;
		
		figure {
			width: 100%;
			height: 250px;
			position: relative;
			overflow: hidden;
			padding: 0;
			margin: 0;
			border-radius: 4px 4px 0 0;
		}
		
		figure img {
			width: 100%;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-radius: 4px 4px 0 0;
		}
		
		h3 {
			margin: 15px 0;
			padding: 0 20px;
			width: 100%;
		}
		
		p {
			width: 100%;
			padding: 0 20px;
		}
		
		a.btn-yellow {
			margin: 0 auto;
		}
	}
}

body.single .related-post { margin-bottom: 30px; }

//==================================================================================
// Single Article
//==================================================================================

body.single .wrap.container {
    max-width: 100%;
    padding: 0;
}

article.post {
	margin-top: 100px;
	
	h1 {
		font-weight: 700;
		margin-bottom: 0;
	}
	
	.byline {
		margin-top: 10px;
	}
	
	figure.post-thumbnail {
		width: 100%;
		max-width: 100%;
		height: auto;
		margin: 40px 0 60px 0;
		text-align: center;
		
		img {
			width: auto;
			max-width: 100%;
			height: auto;
			box-shadow: 0 0 40px -20px rgba($black, 0.4);
		}
		
		figcaption {
			margin-top: 10px;
			font-style: italic;
			text-align: center;
		}
	}
	
	img, video {
		max-width: 100%;
	}
	
	blockquote {
		padding: 50px 40px 40px 50px;
		background-color: $lightgrey;
		position: relative;
		
		p {
			margin: 0;
		}
		
		&::before {
			content: '\201F';
			font-size: 8rem;
			position: absolute;
			top: -25px;
			left: 15px;
			font-family: sans-serif;
		}
	}
	
	.entry-content a {
		@extend %button-inline;
	}
	
	hr {
		height: 2px;
		background-color: $lightgrey;
		border: none;
	}
	
	section.share-buttons {
		h2 {
			margin-bottom: 0;
		}
		
		.socials-shares {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 50px;
			
			a {
				width: 40px;
				height: 40px;
				margin: 10px 10px 10px 0;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 2px;
				
				svg {
					width: 20px;
				}
			}
		}
		
		.share-facebook {
			background-color: #3c5a9a;
			top: 0;
			
			svg {
				height: 20px;
				fill: $white;
			}
		}
		
		.share-twitter {
			background-color: #5da8dc;
			top: 0;
			
			svg {
				fill: $white;
			}
		}
		
		.share-linkedin {
			background-color: #0073b1;
			top: 0;
			
			svg {
				fill: $white;
			}
		}
		
		.share-viadeo {
			background-color: $black;
			top: 0;
			
			svg {
				fill: $white;
			}
		}
	}
}