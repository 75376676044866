//==================================================================================
// Shortcode Formations
//==================================================================================

section.listing-formations {
	h2 {
		font-weight: normal;
	}
	
	h2 > span {
		font-weight: 800;
	}
	
	.listing-formations-left {
		padding-left: calc((100% / 12) * 2);
	}
	
	ul.listing-formations-list {
		padding-left: 0;
		margin-bottom: 30px;
		li {
			margin: 5px 0;
			text-transform: uppercase;
			font-weight: 700;
		}
	}
	
	.listing-formations-right {
		figure {
			height: 100%;
			width: 100%;
			margin-bottom: 0;
		}
	}
	img {
		width: 100%;
		object-fit: cover;
		object-position: center;
		font-family: 'object-fit: cover; object-position: center;';
	}
}