//==================================================================================
// Shortcode Support
//==================================================================================

section.support {
  margin: 20px 0;
  padding: 20px 0;
  
  .item {
    width: 100%;
    padding: 20px;
  }

  p {
    white-space: pre-wrap;
    width: 100%;
  }
  
  h2 {
    color: #444444;
    font-size: 38px;
    line-height: 120%;
    font-weight: normal;
    text-transform: initial;
    width: 100%;
  }

  h2 > span {
    padding-left: -2px;
  }
  
  .support-images-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    
    @include responsive($large) {
      flex-direction: row;
    }
    .img-wrapper {
      padding: 7px;
      
      &.left-img img {
        height: 300px;
      }
      
      &.right-img {
        height: 100%;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin: 5px 0;
      font-family: 'object-fit: cover; object-position: center;';
      
      @include responsive($large) {
        margin: 0;
      }
    }
  }
  
  .support-left {
    & > .row { width: 100%; }
    
    ul { padding-left: 0; }
    a:not(.support-link) { @extend %button-inline; }
    
    & > .row > .col-lg-10 * {
      width: 100%;
    }
    
    li > a {
      width: auto!important;
    }
  }

  .support-left-image, .support-right-image {
    object-fit: cover;
    object-position: center;
  }

  .support-left-image {
    height: 230px;
    width: 100%;
  }

  .support-right-image {
    height: 460px;
    width: 100%;
  }
  
  .support-link {
    margin-bottom: 20px;
    
    @include responsive($large) {
      margin-bottom: 0;
    }
  }
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10 & IE11 CSS styles go here */
    .owl-carousel { display: block!important; }
  }
}