body.blog {
	.wrap.container {
	    max-width: 100%;
	    padding: 0;
	}
	section.header + .container {
		margin: 100px auto 50px auto;
	}
	.post-item {
		margin-bottom: 30px;
		.wrapper {
			height: 100%;
			border: 1px solid #ccc;
		}
		.image {
			height: 300px;
		}
		.image img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			font-family: 'object-fit: cover; object-position: center';
		}
		.text { padding: 15px; }
		a {
			color: black;
		}
	}
}