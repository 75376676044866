//==================================================================================
// Shortcode Team
//==================================================================================

section.team {
	margin: 25px 0;
	h2 {
		font-weight: normal;
		line-height: 120%;
	}
	h2 > span {
		font-weight: 800;
	}
	.item span {
		display: block;
		text-align: center;
	}
	.item .name {
		margin-top: 15px;
		font-weight: bold;
	}
	.item .job {
		font-weight: bold;
		text-transform: uppercase;
	}
	.team-link {
		margin-top: 30px;
	}
	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		right: -15px;
		top: -110px;
		z-index: 10;
		width: 100%;
		padding: 8px 15px;
		.owl-dot span {
			border: 2px solid #444;
			background-color: transparent;
		}
		.owl-dot.active span {
			background-color: #444;
		}
	}
}