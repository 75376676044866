//==================================================================================
// Shortcode Portfolio
//==================================================================================


section.portfolio {
	position: relative;
	padding: 50px 0;
	.container-fluid {
		padding: 0 55px;
	}
	
	&.bg-white {
		background-color: $white;
	}
	
	&.bg-grey {
		background-color: $lightgrey;
	}
	
	.item img {
		width: 100%;
		height: 300px;
		object-fit: cover;
		object-position: center;
		font-family: 'object-fit: cover; object-position: center;';
		
		@include responsive($large) {
			width: 100%;
			height: auto;
		}
	}
	
	h2 {
		color: $black;
		span { font-weight: 800; }
	}
	
	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: absolute;
		right: -15px;
		top: -110px;
		z-index: 10;
		width: 100%;
		padding: 8px 15px;
		.owl-dot span {
			border: 2px solid #444;
			background-color: transparent;
		}
		.owl-dot.active span {
			background-color: #444;
		}
	}
	
	.item {
		width: 100%;
		padding: 30px;
		display: grid;
		grid-template-rows: 300px 15px 300px 15px 300px 15px 300px;
		grid-template-columns: 100%;
		
		@include responsive($large) {
			grid-template-rows: 600px 15px 600px;
			grid-template-columns: calc(100% / 3) 15px calc(100% / 3) 15px calc(100% / 3);
		}
		
		figure {
			margin: 0;
			padding: 7px;
			width: 100%;
			height: 100%;
			
			img {
				width: 100%;
				height: 100%;
			}
		}
		
		figure.item__top-left {
			grid-row-start: 1;
			grid-row-end: span 1;
			grid-column-start: 1;
			grid-column-end: span 1;
			
			@include responsive($large) {
				grid-row-start: 1;
				grid-row-end: span 1;
				grid-column-start: 1;
				grid-column-end: span 1;
			}
		}
		
		figure.item__top-right {
			grid-row-start: 3;
			grid-row-end: span 1;
			grid-column-start: 1;
			grid-column-end: span 1;
			
			@include responsive($large) {
				grid-row-start: 1;
				grid-row-end: span 1;
				grid-column-start: 3;
				grid-column-end: span 1;
			}
		}
		
		figure.item__right {
			grid-row-start: 5;
			grid-row-end: span 1;
			grid-column-start: 1;
			grid-column-end: span 1;
			
			@include responsive($large) {
				grid-row-start: 1;
				grid-row-end: span 3;
				grid-column-start: 5;
				grid-column-end: span 1;
			}
		}
		
		figure.item__bottom {
			grid-row-start: 7;
			grid-row-end: span 1;
			grid-column-start: 1;
			grid-column-end: span 1;
			
			@include responsive($large) {
				grid-row-start: 3;
				grid-row-end: span 1;
				grid-column-start: 1;
				grid-column-end: span 3;
			}
		}
	}
	
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		/* IE10 & IE11 CSS styles go here */
		.owl-carousel { display: block!important; }
	}
}
