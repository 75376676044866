//==================================================================================
// Shortcode Innovation
//==================================================================================

section.innovation {
  h2 {
    font-size: 35px;
    margin-top: 0;
    margin-bottom: 15px;
    padding: 0 55px;
  }
  h2 span {
    text-transform: uppercase;
    font-weight: 800;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 55px;
    border-right: 1px solid #DBDEED;
    .img-wrapper,
    .content {
      width: 50%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      font-family: 'object-fit: contain; object-position: center;';
    }
    .content {
      margin-left: 30px;
        p {
          margin-bottom: 0;
        }
    }
    h4 {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
  .owl-dots {
    position: absolute;
    top: -76px;
    right: 55px;
  }
  .owl-dot span {
    background-color: transparent !important;
    border: 1px solid #444;
  }
  .owl-dot.active span {
    background-color: #444444 !important;
  }
}