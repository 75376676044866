//==================================================================================
// Shortcode Post
//==================================================================================

section.post {
	border-top: 1px solid #DBDEED;
	border-bottom: 1px solid #DBDEED;
	.left {
		background-color: #F4F5FA;
		padding: 20px;
		
		@include responsive($large){
			padding: 40px 60px 80px 60px;
		}
	}
	.right {
		display: flex;
		align-items: center;
    	justify-content: center;
		background-color: #DBDEED;
		padding: 10px;
		
		@include responsive($large) {
			padding: 32px;
		}
	}
	.top-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-bottom: 20px;
		
		@include responsive($large) {
			align-items: center;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: 80px;
		}
		
		.text-right {
			text-align: left!important;
			
			@include responsive($large) {
				text-align: right !important;
			}
		}
	}
	.item:first-child {
		margin: 10px 0;
		
		@include responsive($large) {
			padding: 30px 100px 30px 15px;
			margin: 0;
		}
	}
	.item:last-child {
		margin: 10px 0;
		border-left: 1px solid #DBDEED;
		
		@include responsive($large) {
			padding: 30px 15px 30px 60px;
			margin: 0;
		}
	}
	.content {
		display: flex;
		align-items: flex-start;
	}
	.text {
		margin-left: 30px;
		a:not(.button-inline):before {
			display: none;
		}
	}
	h3 {
		color: #444;
		font-size: 18px !important;
		margin-bottom: 0;
		font-weight: 600;
	}
	span.date {
		display: block;
		font-size: 12px;
		font-style: italic;
    	margin: 5px 0;
	}
	span.date + a {
		font-size: 12px;
		color: #444;
		text-decoration: underline !important;
	}
	
	#fb-root {
		display: none;
	}
	
	/* To fill the container and nothing else */
	
	//.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
	//	width: 100% !important;
	//}
}