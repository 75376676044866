//==================================================================================
// Shortcode Résultats Recherche Formations
//==================================================================================

section.results-formation {
	.results-formation__sidebar {
		margin-top: 50px;
		
		ul {
			padding: 5px 0 10px 10px;
			border-left: 2px solid $lightgrey;
			list-style: none;
		}
		
		ul li {
			padding: 5px 0;
		}
	}
	
	.results-container {
		@include responsive($medium) {
			padding: 50px 0 30px 20px;
		}
	}
	
	.results-container__category {
		width: 100%;
		border-top: 2px solid $lightgrey;
		
		@include responsive($medium) {
			padding: 20px 0;
		}
		
		.results-item__image {
			width: 100%;
			height: 200px;
			
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				border-radius: 4px;
				font-family: 'object-fit: cover; object-position: center;';
			}
		}
		
		.job-header {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			margin-bottom: 25px;
			
			@include responsive($medium) {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
			
			h2 {
				text-transform: uppercase;
				font-weight: bold;
				
				@include responsive($medium) {
					margin-bottom: 0;
				}
			}
		}
		
		.results-item__description {
			margin-bottom: 20px;
			
			a { @extend %button-inline; }
		}
	}
	
	.results-container__category-container {
		margin: 0;
	}
	
	.results-container__item {
		box-shadow: 0 2px 2px 2px rgba($lightgrey, 0.5);
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 20px;
		
		@include responsive($medium) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		
		h3 {
			font-weight: bold;
			text-transform: uppercase;
			
			@include responsive($medium) {
				margin-bottom: 0;
			}
		}
	}
}