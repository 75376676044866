//==================================================================================
// Options Visual Composer
//==================================================================================

.wrap.container {
  padding-top: 75px!important;
  
  @include responsive($large) {
    padding-top: 126px!important;
  }
}

.vc_row-has-fill+.vc_row-full-width+.vc_row>.vc_column_container>.vc_column-inner,
.vc_row-has-fill>.vc_column_container>.vc_column-inner {
  padding-top: 0;
}

.vc_row[data-vc-full-width] {
  overflow: visible!important;
}

.wpb_content_element.vc_sep_color_grey {
  margin-bottom: 0;
}

.wpb_wrapper {
  height: 100%!important;
}

#init-fb-sync {
  display: none;
}

.wpb_content_element { margin-bottom: 0; }

.vc_custom_1558595680170 { margin: 0 !important; }

/**
 * Full size template
 */
.single-job .wrap.container, .single-job .wrap > .row, .single-job .main,
.single-formation .wrap.container, .single-formation .wrap > .row, .single-formation .main {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

//==================================================================================
// Style FAQ
//==================================================================================

//.vc_toggle_title {
//  padding: 15px 10px;
//  color: $white;
//  background-color: $black;
//  text-transform: uppercase;
//  transition: all 0s ease-in-out;
//
//  h4 {
//    font-size: 14px;
//    font-weight: bold;
//  }
//
//  i {
//    background: $white;
//  }
//}
//
//.vc_toggle_content img {
//  width: 100%;
//  max-width: 700px;
//}
//
//.vc_toggle_default .vc_toggle_icon {
//  background: $white;
//  border: 1px solid $white;
//
//  &::after, &::before {
//    background: $white;
//    border: 1px solid $white;
//  }
//}
//
//.vc_toggle_size_md.vc_toggle_default .vc_toggle_title .vc_toggle_icon,
//.vc_toggle_default .vc_toggle_title .vc_toggle_icon {
//  right: 20px;
//  left: initial;
//}
//
//.vc_toggle_content {
//  background-color: $white;
//  padding-left: 5px!important;
//
//  @include responsive($large) {
//    padding-left: 24px!important;
//  }
//}
//
//.vc_toggle_active {
//
//  .vc_toggle_title {
//    color: $primary-color;
//    background-color: $secondary-color;
//  }
//
//  &.vc_toggle_default .vc_toggle_icon {
//    background: $primary-color;
//    border: 1px solid $primary-color;
//
//    &::after, &::before {
//      background: $black;
//      border: 1px solid $black;
//    }
//  }
//}

//==================================================================================
// Style Accordéons
//==================================================================================

.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading {
  background-color: $black!important;
  border-color: $black !important;
  
  &:hover {
    background-color: darken($black, 5%) !important;
    border-color: darken($black, 5%) !important;
  }
}

.vc_tta.vc_general .vc_tta-panel.vc_active > .vc_tta-panel-heading {
  background-color: $yellow !important;
  border-color: $yellow !important;
  
  &:hover {
    background-color: darken($yellow, 5%) !important;
    border-color: darken($yellow, 5%) !important;
  }
}

.vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-title > a > span {
  color: $white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
}

.vc_tta.vc_general .vc_tta-panel.vc_active > .vc_tta-panel-heading > .vc_tta-panel-title > a > span {
  color: $black;
}

.vc_tta-panel-body img {
  width: 100%;
  max-width: 600px;
}

.vc_tta-panel-title {
  position: relative;
}

.vc_tta-panel-title::before {
  content: url('/wp-content/uploads/2019/07/accordeon-open.svg');
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
}

.vc_tta-panel.vc_active .vc_tta-panel-title::before {
  content: url('/wp-content/uploads/2019/07/accordeon-close.svg');
  pointer-events: none;
}

.wpb_single_image.vc_align_left {
  @include responsive-max($medium) {
    text-align: center;
    margin-bottom: 20px;
  }
  @include responsive($medium) {
    text-align: left;
    margin-bottom: 0;
  }
  
  .vc_single_image-wrapper {
    @include responsive-max($medium) {
      margin-bottom: 30px;
    }
    @include responsive($medium) {
      margin-bottom: 0;
    }
  }
}

//==================================================================================
// Modifications page par page
//==================================================================================

/**
 * Pages Centre
 */

.centers-empty-space-top {
  height: 120px!important;
}

body.page-id-89 .vc_single_image-wrapper {
  @include responsive-max($medium) {
    margin-bottom: 0!important;
  }
  @include responsive($medium) {
    margin-bottom: 0;
  }
}

.about-us-empty-space-bottom {
  height: 40px!important;
  
  @include responsive($large) {
    height: 80px!important;
  }
}

/**
 * Page Partenaires
 */

.partners-empty-space-top {
  height: 40px!important;
  
  @include responsive($large) {
    height: 120px!important;
  }
}

.partners-empty-space-bottom {
  height: 40px!important;
  
  @include responsive($large) {
    height: 80px!important;
  }
}

/**
 * Pages avec image de gauche très haute
 */

body:not(.home) .vc_row-o-equal-height .vc_col-has-fill {
  height: 400px;
  width: 100%;
  margin-bottom: 20px;
  
  @include responsive($large) {
    height: auto;
    width: 33.33333333%;
    margin-bottom: 0;
  }
}

.header.no-print + .vc_row.wpb_row.vc_row-fluid {
  margin: 0;
}

footer.content-info > .container-fluid > .row > .col-lg-9 > .row {
  margin: 0;
}

.image-full-responsive img {
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  height: 400px;
  margin-bottom: 20px;
  font-family: 'object-fit: cover; object-position: center;';
  
  @include responsive($large) {
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
}


/**
 * Page Formations obligatoires et recommandées
 */

.formations-description > div {
  padding-top: 0!important;
  
  @include responsive($large) {
    padding-top: 50px!important;
  }
}