.card {
	padding-top: 100px;
	padding-bottom: 100px;
	border: none;
	border-top: 1px solid #dbdeed;
	border-radius: 0;
}
.card .col-md-4 { margin-bottom: 30px; }
.card__title {
	font-size: 35px;
	font-weight: 800;
	text-transform: uppercase;
	margin-bottom: 30px;
}
.card__item {
	height: 100%;
	border: 1px solid #dbdeed;
}
.card__item__title,
.card__item__text { padding: 0 15px; }
.card__item__title {
	font-size: 20px;
	margin-bottom: 20px;
}
.card__item__date {
 	transform: translateY(-100%);
    background-color: #fe0;
    display: inline-block;
    font-weight: 800;
    font-size: 16px;
    padding: 8px 15px;
}
.card__item__btn-wrapper {
	padding: 0 15px;
	margin-bottom: 30px;
}
.card__item__btn {
	display: block;
	font-weight: 700;
	text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #444;
    padding: 12px 30px;
    margin-top: 30px;
    border-radius: 4px;
    background-color: #fe0;
    transition: all .3s; 
}
.card__item__btn:hover {
	color: white;
	background-color: #444;
	text-decoration: none;
}
.card .owl-nav {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
}
.card .owl-nav .owl-prev span,
.card .owl-nav .owl-next span {
	font-size: 50px;
	font-weight: bold;
	line-height: 0;
}
.card .owl-nav .owl-prev {
	position: absolute;
	left: -50px;
}
.card .owl-nav .owl-next {
	position: absolute;
	right: -50px;
}
.card .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
}
.card .owl-dots .owl-dot span {
	display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 5px;
    border: 2px solid #444;
    background-color: transparent;
}
.card .owl-dots .owl-dot.active span {
    background-color: #444;
}