//==================================================================================
// Shortcode Partner
//==================================================================================

section.partner {
  background-color: #F4F5FA;
  border-top: 1px solid #DBDEED;
  border-bottom: 1px solid #DBDEED;
  padding: 30px 15px;
  .row {
    align-items: center;
  }
  .col-lg-8 {
    margin-top: 20px;
    
    @include responsive($large) {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .owl-item {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
  .item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item img {
    width: auto;
    height: 80px;
  }
  .owl-nav {
    margin-top: 0;
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .owl-prev {
      left: -80px;
    }
    .owl-next {
      right: 0px;
    }
  }
}