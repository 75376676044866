/* Popup */
.sgpb-popup-close-button-3 {
  box-sizing: border-box;
  padding-left: 38px;
  overflow: hidden;
  background-image: url(/wp-content/uploads/2019/09/close.png);
  background-size: auto !important;
  transform: translateX(25px);
}

.newsletter-submit {
  padding: 10px 0;
  width: 100%;
  margin-top: 20px;
}

//==================================================================================
// Fixed CTA
//==================================================================================

.body-fixed-cta {
	display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin-top: 30px;
	
	@include responsive($large)
	{
		height: auto;
		position: fixed;
		left: 0;
		top: 62vh;
		z-index: 98;
		align-items: flex-start;
		padding: 0;
		margin-top: 0;
	}
	
	a {
		display: flex !important;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		font-size: 12px;
		border-radius: 0;
		font-weight: 400 !important;
		padding: 8px 10px !important;

		figure {
			display: none;

			@include responsive($large) {
				display: block;
				margin: 0;
				padding: 0;
				width: 50px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 30px;
					max-height: 25px;
				}
			}
		}
		
		@include responsive($large) {
			padding: 0!important;
			max-width: inherit;
			width: auto;
			position: relative;
			transition: left 0.2s ease-in-out;

			span {
				padding: 12px 10px;
			}

			&:hover {
				left: 0!important;
			}
		}
	}
}


//==================================================================================
// Header - Généralités
//==================================================================================

.banner {
	width: 100%;
	height: 100vh;
	padding-bottom: 40px;
	overflow: visible;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	background-color: $white;
	transform: translateX(-150vw);
	transition: all 0.3s ease-in-out;
	
	&.visible {
		transform: translateX(0);
		@include small() {
			overflow: scroll;
		}
	}
	
	@include responsive ($large) {
		transform: translateX(0);
		height: auto;
		overflow: initial;
		padding-bottom: 0;
	}
	
	li, a {
		font-weight: bold;
		text-transform: uppercase;
		color: $black;
	}
}

//==================================================================================
// Header Responsive : Logo + Menu burger
//==================================================================================

.header-responsive {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 75px;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	background-color: $white;
	box-shadow: 0 1px 4px 2px rgba($lightgrey, 0.5);
	
	
	img {
		width: 250px;
	}
	
	.menu-burger {
		height: 25px;
		width: 25px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-around;
		cursor: pointer;
		
		.menu-burger-bars {
			width: 100%;
			height: 3px;
			background-color: $black;
		}
	}
}

.header-active-mobile {
	width: 100%;
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 15px;
	
	img {
		width: 250px;
	}
	
	.menu-burger-close {
		cursor: pointer;
		width: 25px;
		height: 25px;
		position: relative;
		margin-right: 20px;
		
		.menu-burger-close__bars {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 2px;
			width: 27px;
			background-color: $black;
		}
		
		.menu-burger-close__bar-left {
			transform: rotate(45deg);
		}
		
		.menu-burger-close__bar-right {
			transform: rotate(-45deg);
		}
	}
}


//==================================================================================
// Header Haut : RS + connexion/inscription
//==================================================================================

.header-top.header-top-resp {
	display: block !important;
	margin-top: 20px;
	@include responsive($large) { display: none !important; }
}

.header-top {
	flex-direction: column!important;
	display: none !important;
	
	@include responsive($large) {
		display: flex !important;
		height: 40px;
		flex-direction: row!important;
		justify-content: space-evenly;
		border-bottom: solid 1px #DBDEED;
	}
	
	.social-medias {
		width: 100%;
		margin: 15px 0;
		padding: 10px;
		justify-content: space-evenly!important;
		padding-left: 0!important;
		
		@include responsive($large) {
			margin: 0;
			height: 100%;
			width: auto;
			justify-content: flex-start!important;
		}
	}
	
	.social-medias a {
		height: 100%;
		margin: 0px 5px;
		padding: 0px 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.register-login {
		flex-direction: column!important;
		justify-content: flex-start!important;
		
		@include responsive($large) {
			height: 100%;
			flex-direction: row!important;
			justify-content: flex-end!important;
		}
		
		.headercta {
			font-size: 14px;
			height: 45px;
			width: 100%;
			max-width: 300px;
			margin: 10px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0;
			
			@include responsive($large) {
				height: 100%;
				font-size: 12px;
				width: auto;
				max-width: none;
				margin: 0;
			}
		}
	}
}

//==================================================================================
// Header Bas : logo + navigation
//==================================================================================

.header-bottom {
	@include responsive($large) {
		border-bottom: solid 1px #DBDEED;
		display: grid;
		grid-template-rows: 85px;
		grid-template-columns: 250px 1fr 20px;
		align-items: center;
	}
	
	.brand {
		padding-left: 30px;
		@include responsive($large) {
			grid-column-start: 1;
			grid-column-end: span 1;
			grid-row-start: 1;
			grid-row-end: span 1;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
		}
		
		img {
			display: block;
			width: 100%;
			height: auto;
			min-height: 1px;
		}
	}
}

//==================================================================================
// Méga Menu
//==================================================================================

/**
 * Items de premier niveau
 * Visibles de base
 */

.header-bottom {
	nav.nav-primary {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		
		@include responsive($large) {
			grid-column-start: 2;
			grid-column-end: span 1;
			grid-row-start: 1;
			grid-row-end: span 1;
			height: 100%;
			position: relative;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	}
	
	ul.nav {
		width: 100%;
		padding: 0;
		display: block;
		flex-direction: column;
		
		@include responsive($large) {
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}
		
		li {
			height: auto;
			width: 100%;
			
			@include responsive($large) {
				height: 100%;
				width: 18%;
			}
		}
		
		& > li {
			display: inline-block;
			width: auto;
			border-top: 2px solid $lightgrey;
			
			@include responsive($large) {
				&:first-child, &:nth-child(3), &:nth-child(4) {
					width: 15%;
				}
				&:nth-child(2), &:last-child {
					width: 25%;
				}
				border: none;
				padding: 0 20px;
			}
		}
		
		& > li > a {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: 1.5rem;
			text-align: center;
			
			@include responsive($large) {
				height: 100%;
				justify-content: center;
				position: relative;
				font-size: 1rem;
				
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					height: 0;
					bottom: 0;
					left: 0;
					background-color: $secondary-color;
					transition: all 0.3s ease-in-out;
				}
			}
		}
		
		& > li > .sub-menu {
			@include responsive($large) {
				visibility: hidden;
				opacity: 0;
				transition: all 0.2s ease-in-out;
			}
		}
		
		@include responsive($large) {
			& > li:hover {
				& > a::before {
					height: 4px;
				}
				
				& > .sub-menu {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
	
	/**
	 * Items de second niveau
	 * Avec sous-menu
	 */
	
	ul.nav > .menu-item-has-children {
		position: static;
		display: block;
		padding: 10px 15px;
		font-size: 18px;

		@include responsive($large) {
			display: inline-block;
			font-size: 14px;
			padding: 0 20px;
		}
		
		& > .sub-menu {
			padding-left: 10px;
			font-size: initial;
			display: none;
			
			@include responsive($large) {
				position: absolute;
				width: calc(100% + 15px);
				padding: 30px 30px 15px 30px;
				background-color: $white;
				left: 15px;
				border: 1px solid $lightgrey;
				display: block;
			}

		}
		
		& > .sub-menu > .menu-item-has-children {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
			list-style: none;
			
			@include responsive($large) {
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
			}
		}
		
		& > .sub-menu > .menu-item-has-children > a {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-transform: initial;
			padding: 10px;
			font-size: 1.25rem;
			cursor: default;
			
			@include responsive($large) {
				width: 35%;
				font-size: 16px;
			}
		}
		
		/**
		 * Items de troisième niveau
		 */
		
		& > ul.sub-menu > .menu-item-has-children > ul.sub-menu {
			position: static;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding-left: 10px;
			
			@include responsive($large) {
				width: calc(100% + 50px);
				padding: 25px 0;
				top: 85px;
				left: 0 !important;
				right: unset;
				bottom: unset;
				flex-direction: row;
				align-items: flex-start;
				justify-content: flex-start;
				flex-wrap: wrap;
				//display: grid;
				//grid-template-columns: 1fr 30px 1fr 30px 1fr;
				//align-items: flex-start;
				transform: translate(0);
			}
			
			& > li.menu-item {
				width: 100%;
				margin: 0!important;
				list-style: none;
				position: relative;
				
				@include responsive($large) {
					width: 30%;
					margin: 0 10px!important;
				}
			}
			
			@include responsive($large) {
				& > li.menu-item::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 100%;
					background-color: $lightgrey;
					transition: all 0.3s ease-in-out;
					z-index: -1;
				}
				
				& > li.menu-item:hover::before {
					width: 100%;
				}
				
				& > li:nth-child(n+4) {
					border-top: 2px solid $lightgrey;
				}
			}
			
			a {
				width: 100%;
				font-weight: normal;
				text-transform: uppercase;
				padding: 10px;
			}
		}
	}
}

//==================================================================================
// Icônes réseaux sociaux
//==================================================================================

header.banner, footer {
	i.fab {
		color: #BABABA;
		font-size: 16px;
		opacity: 0.9;
		transition: opacity 0.2s ease-in-out;
	}
	
	i.fa-facebook-f {
		color: #3C5A99;
	}
	
	i.fa-twitter {
		color: #1DA1F2;
	}
	
	i.fa-linkedin {
		color: #0077B5;
	}
	
	i.fa-instagram {
		color: #16161D;
	}
	
	i.fa-youtube {
		color: #ff0000;
	}
	
	a:hover i.fab {
		opacity: 1;
	}
}

//==================================================================================
// Barre de recherche
//==================================================================================

.header-search-icon {
	height: 100%;
	padding: 0 0 0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $black;
	text-align: center;
}

.header-search-form, .search form {
	input {
		height: 60px;
		border: none;
		outline: 0;
	}
	
	button {
		height: 60px;
		border: 0;
		border-radius: 0;
	}
}

.header-search-form {
	
	@include responsive($large) {
		opacity: 0;
		display: none;
		visibility: hidden;
		transition: all 1s ease-in-out;
		position: absolute;
		width: 100%;
		bottom: -60px;
		left: 0;
		
		&.active {
			display: block;
			visibility: visible;
			opacity: 1;
		}
	}
}