//==================================================================================
// Formulaires
//==================================================================================


//==================================================================================
// Inclus par défaut par WordPress
//==================================================================================

.search-form {
    @extend .form-inline;
  }
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


//==================================================================================
// Contact Form 7
//==================================================================================

body.search form, .wpcf7-form {
  width: 95%;
  max-width: 600px;
  margin: 0 auto;
  
  .container {
    padding: 15px;
  }
  
  .form-field {
    width: 100%;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    & > span {
      width: 100%;
    }
  
    select, input[type=radio], input[type=checkbox] {
      appearance: none;
    }
    
    label {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0;
    }
    
    input[type=text], input[type=date], input[type=email], input[type=tel],
    select,
    textarea {
      width: 100%;
      min-height: 45px;
      padding: 0 5px;
      border: 2px solid $lightgrey;
      border-radius: 2px;
      transition: all 0.2s ease-in-out;
      outline: 0;
      
      &:active, &:focus {
        border: solid 2px $black;
      }
    }
    
    input[type=radio], input[type=checkbox] {
      appearance: none;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border: 2px solid $lightgrey;
      transition: all 0.2s ease-in-out;
      
      &:checked {
        background-color: $black;
        border: 2px solid $black;
      }
    }
  
    input[type=radio] {
      border-radius: 50px;
    }
  
    input[type=checkbox] {
      border-radius: 4px;
    }
  
    .wpcf7-form-control.wpcf7-radio {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      
      label {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
      }
    }
    
    textarea {
      resize: vertical;
      padding: 10px;
    }
    
    select {
      background-color: transparent;
    }
  }
  .wpcf7-form-control-wrap.form-select,
  .form-select {
    position: relative;
    
    &::before {
      content: url('/wp-content/uploads/2019/07/arrow-down-outline.svg');
      position: absolute;
      top: 13px;
      right: 10px;
      height: 10px;
      width: 20px;
      pointer-events: none;
    }
  }
  input[type="submit"] {
    @extend %button-yellow;
    border-bottom: none;
  }
}