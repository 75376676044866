//==================================================================================
// Single Job
//==================================================================================

.jobs-container {
	font-size: 1rem;
	
	.intro {
		margin-bottom: 60px;
	}
	
	.item {
		border-top: 1px solid #DBDEED;
	}
	
	.item:nth-child(even) > .row {
		flex-wrap: wrap-reverse;
	}
	
	.item .image-wrapper {
		padding: 0;
		
		img {
			height: 250px;
			@include responsive($large) {
				width: 100%;
			}
		}
	}
	.item .content-wrapper {
		padding: 10px;
		
		@include responsive($large) {
			padding: 35px 65px;
		}
		
		h4 {
			font-size: 20px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 25px;
		}
		
		.content {
			line-height: 25px;
		}
		
	}
	
	.item .content-parent {
		padding: 0;
	}
	
	.item .list-wrapper {
		font-weight: 700;
		background-color: #E5E7F1;
		border-top: 1px solid #DBDEED;
		border-bottom: 1px solid #DBDEED;
		padding: 10px;
		
		@include responsive($large) {
			padding: 30px 65px;
		}
		
		ul {
			padding-left: 20px;
		}
		
		li {
			text-transform: uppercase;
		}
	}
}

body.single-job {
	section.testimonials {
		background-color: $lightgrey;
	}
	
	.vc_row-has-fill>.vc_column_container>.vc_column-inner {
		padding-top: 0;
	}
	video {
	    width: 100%;
	    height: auto;
	    margin: 50px 0;
	}
}