//==================================================================================
// Shortcode Job List
//==================================================================================

section.job-list {
	margin: 25px 0;
	padding: 25px 50px;
	
	h2 {
		text-align: center;
	}
	
	.jobs-listing {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;
		
		@include responsive($large) {
			justify-content: flex-start;
		}
	}
	
	.job-list-item-1, .job-list-item-2, .job-list-item-3 {
		width: calc(100% - 20px);
	}
	
	.job-list-item-1 {
		@include responsive($large) {
			width: calc((100% / 3) - 20px);
			margin: 10px;
		}
	}
	
	.job-list-item-2 {
		@include responsive($large) {
			width: calc(((100% / 3) * 2) - 20px);
			margin: 10px;
		}
	}
	
	.job-list-item-3 {
		@include responsive($large) {
			width: calc(100% - 20px);
			margin: 10px;
		}
	}
	
	.job-list-item {
		position: relative;
		height: 400px;
		overflow: hidden;
		
		.item__front {
			span {
				height: 50px;
				width: 100%;
				font-weight: 700;
    			text-transform: uppercase;
				background-color: $white;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				text-align: center;
			}
			
			img {
				width: 100%;
				height: 350px;
				object-fit: cover;
				object-position: center;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				font-family: 'object-fit: cover; object-position: center;';
			}
		}
		
		.item__back {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translateX(-100%);
			background-color: rgba($yellow, 0.7);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			transition: all 0.3s ease-in-out;
			padding: 30px;
			
			span {
				color: $white;
				text-transform: uppercase;
				font-size: 25px;
				font-weight: 800;
				margin-bottom: 20px;
				text-align: center;
			}
		}
	}
	
	.job-list-item:hover {
		.item__front {
			span {
				transform: translateY(-50px);
				text-align: center;
			}
			
			img {
				transform: translateY(-50px);
				height: 400px;
			}
		}
		.item__back {
			transform: translateX(0);
		}
	}
}