//==================================================================================
// Shortcode Page Top
//==================================================================================

section.header {
	position: relative;
	&:after {
	    content: url(/wp-content/uploads/2019/07/mini-logo.svg);
	    position: absolute;
	    bottom: 0;
	    left: 50%;
	    transform: translate(-50%, 80%);
	    z-index: 10;
	}
	.header-container {
		padding: 150px 15px;
		background-size: cover;
		background-position:center top;
		background-repeat: no-repeat;
		position: relative;
		
		@include responsive($large) {
			background-size: cover;
			background-position: center;
		}
		
		.row {
			align-items: center;
		}
		
		h1 {
			font-size: 30px;
			font-weight: 800;
			text-transform: uppercase;
			color: $white;
			line-height: 40px;
			margin-bottom: 0;
			
			span {
				font-weight: 400;
				text-transform: initial;
			}
		}
		
		a.contact {
			margin-bottom: 30px;
		}
		
		a.contact,
		a.doc {
			width: 215px;
			text-align: center;
			padding: 12px 25px;
		}
	}
	
	.breadcrumb-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: rgba($black, 0.8);
		
		#breadcrumbs {
			padding: 10px 30px;
			margin: 0;
			color: $white;
		}
		
		#breadcrumbs a {
			color: $white;
		}

		#breadcrumbs > span > span > span > a {
		    pointer-events: none;
		}
	}
	
	.mini-logo-pagetop {
		position: absolute;
		bottom: -70px;
		left: 50%;
		transform: translateX(-50%);
		width: 70px;
		z-index: 90;
		outline-color: none;
	}
	
	.pagetop-ctas {
		position: absolute;
		bottom: -45px;
		right: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: visible;
		z-index: 90;
		
		div {
			width: 45px;
			height: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		
		.social-shares {
			background-color: $black;
			position: relative;
			overflow: visible;
			
			label {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 2;
				margin: 0;
				cursor: pointer;
			}
			
			& > svg {
				fill: $white;
			}
			
			.medias-share {
				position: absolute;
				left: 0;
				height: 45px;
				width: 45px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				visibility: hidden;
				opacity: 0;
				transition: all 0.2s ease-in-out;
				z-index: 90;
				
				& > svg {
					width: 20px;
				}
				
				&.visible {
					visibility: visible;
					opacity: 1;
				}
			}
			
			.share-facebook {
				background-color: #3c5a9a;
				top: 0;
				
				svg {
					height: 20px;
					fill: $white;
				}
				
				&.visible {
					top: -45px;
				}
			}
			
			.share-twitter {
				background-color: #5da8dc;
				top: 0;
				
				svg {
					fill: $white;
				}
				
				&.visible {
					top: -90px;
				}
			}
			
			.share-linkedin {
				background-color: #0073b1;
				top: 0;
				
				svg {
					fill: $white;
				}
				
				&.visible {
					top: -135px;
				}
			}
			
			.share-viadeo {
				background-color: $black;
				top: 0;
				
				svg {
					fill: $white;
				}
				
				&.visible {
					top: -180px;
				}
			}
		}
		
		.print-page {
			background-color: $white;
			border: 1px solid $lightgrey;
			margin-left: 5px;
			
			svg {
				fill: $black;
			}
		}
	}
	
	.text-right {
		text-align: left!important;
		
		@include responsive($large) {
			text-align: right!important;
		}
	}
}