//==================================================================================
// Page 404
//==================================================================================

.section-404 {
	height: 90vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	h1 {
		--c01: red;
		--c02: #00f;
		--c03: #0f0;
		font-size: 20rem!important;
		font-weight: 900;
		animation: glitch 2s steps(100) infinite;
	}
	
	a {
		@extend %button-yellow;
	}
}